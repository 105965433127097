<template>
  <div class="esign">
    <div class="esign-bg"></div>
    <div class="esign-cav">
      <vue-esign
        ref="esign"
        :width="height"
        :height="width"
        :isCrop="isCrop"
        :lineWidth="lineWidth"
        :lineColor="lineColor"
        :bgColor.sync="bgColor"
      />
    </div>
    <div class="esign-box">
      <div class="esign-box-reset" @click="handleReset">
        <img src="@/assets/reset.png" alt="" />
      </div>
      <div class="esign-box-ok" @click="handleGenerate">
        <img src="@/assets/signok.png" alt="" />
      </div>
    </div>
    <div class="esign-title">
      <img @click="goBack" class="esign-title-img" src="@/assets/close.png" alt="" />
      <div class="esign-title-text">聚优企电子签约系统</div>
    </div>
  </div>
</template>

<script>
import vueEsign from "vue-esign";
import { savePersonSeal } from '@/util/api'
import { oss } from '@/util/tool'
import { mapState } from 'vuex'

export default {
  components: { vueEsign },
  data() {
    return {
      lineWidth: 4,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: true,
      width: document.documentElement.offsetHeight,
      height: document.documentElement.clientWidth,
    };
  },
  computed: {
    ...mapState(['linkCodeInfo'])
  },
  methods: {
    handleReset() {
      this.$refs.esign.reset();
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          console.log("res", res);
          const path = this.dataURLtoFile(res)

          oss(path).then(({size, object, host}) => {
            const params = {
              files: [{ fileDisplayName: 'signImage', fileExt: '.png', fileSize: size, fileUrl: `${host}${object}` }],
              processId: `${this.linkCodeInfo.processId}`,
              height: this.height,
              width: this.width
            }

            savePersonSeal(params).then(() => {
              this.goBack()
            })
          })
        })
        .catch((err) => {
          alert(err.msg); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    goBack() {
      this.$router.back(1)
    },
    random_string(len) {
      len = len || 32
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      var maxPos = chars.length
      var pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    }
  },
  mounted() {},
};
</script>

<style lang='less' scoped>
.esign {
  width: 100%;
  height: 100%;
  /* transform: rotate(90deg); */
  position: relative;
  overflow: hidden;

  background: #f5f6f9;
  &-bg {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/draw-bg.png");
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: 10;
  }
  &-cav {
    z-index: 11;
    position: absolute;
    left: 0;
    top: 0;
  }
  &-box {
    width: 40px;
    height: 100%;
    // transform-origin: center;
    // transform: rotate(90deg);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 14;
    // background: red;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &-reset,
    &-ok {
      width: 26px;
      height: 26px;
      margin-bottom: 30px;
      transform-origin: center;
      transform: rotate(90deg);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-title {
    width: 50px;
    height: 100%;
    background: #0067e1;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 12;
    &-img {
      width: 22px;
      height: 22px;
      display: inline-block;
      position: fixed;
      right: 14px;
      top: 20px;
    }
    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 200px;
      height: 26px;
      margin-left: -13px;
      margin-top: -100px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;

      transform-origin: left top;
      transform: rotate(90deg) translateY(-100%);
    }
  }
}
</style>